import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Services from './Components/WebPages/Services';
import AboutUs from './Components/WebPages/AboutUs/AboutUs';
import FAQs from './Components/WebPages/FAQs/FAQs';
import Home from './Components/WebPages/Home';
import ContactUs from './Components/WebPages/ContactUs/ContactUs';
import TermandConds from './Components/WebPages/TermandConds';
import PricingPolicy from './Components/PricingPolicy/PricingPolicy';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';

import RefundPolicy from './Components/RefundPolicy/RefundPolicy';
import ScrollButton from './Components/ScrollButton.js/ScrollButton';
import Login from './Components/CustomerRegister/Login/Login'
import './App.css';
import SignUp1 from './Components/CustomerRegister/SignUp/SignUp1';
import SignUp3 from './Components/CustomerRegister/SignUp/SignUp3';
const Customer = localStorage.getItem("customer") || null;

const App = () => {
  const [customer, setCustomer] = useState(Customer);
  return (
    <Router>
      <Navbar customer={customer} setCustomer={setCustomer} />
      <Switch>
        <Route exact path='/' render={(props) => (
          <Home {...props} customer={customer} />
        )} />
        <Route path='/customerlogin' render={(props) => (
          <Login {...props} handlerCustomer={setCustomer} />
        )} />
        <Route path='/customersignup1' component={SignUp1} />
        <Route path='/customerOTP' render={(props) => (
          <SignUp3 {...props} />
        )} />
        <Route path='/services' component={Services} />
        <Route path='/about-us' component={AboutUs} />
        <Route path='/faqs' component={FAQs} />
        <Route path='/contact-us' component={ContactUs} />
        <Route path='/terms-and-conditions' component={TermandConds} />
        <Route path='/privacy-policy' component={PrivacyPolicy}/>
        <Route path='/pricing-policy' component={PricingPolicy} />
        <Route path='/refund-policy' component={RefundPolicy} />
      </Switch>
      <ScrollButton />
    </Router>

  );
}

export default App;
