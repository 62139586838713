//1
import { BASE_URL } from './configure'
const log = console.log();
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

export async function loginApi(formdata) {
  const url = `${BASE_URL}signin`;

  const requestOptions = {
    method: "POST",
    body: formdata,
  };
  const data = await fetch(url, requestOptions
  ).catch((err) => {

  });
  const payload = data && data.json();
  return payload;
}
export async function contactUsApi(formdata) {
  const url = `${BASE_URL}contact_us`;

  const requestOptions = {
    method: "POST",
    body: formdata,
  };
  const data = await fetch(url, requestOptions
  ).catch((err) => {

  });
  const payload = data && data.json();
  return payload;
}


export async function signupApi(formData) {
  const url = `${BASE_URL}signup_customer`;
  const data = await fetch(url, {
    method: "POST",
    body: formData,
  }).catch((err) => {
    log("Api error: ", err);
  });
  const payload = data && data.json();
  return payload;
}

export async function bookServiceApi(formData) {
  const url = `${BASE_URL}add_appointment`;
  const data = await fetch(url, {
    method: "POST",
    body: formData,
  }).catch((err) => {
    log("Api error: ", err);
  });
  const payload = data && data.json();
  return payload;
}


export async function phoneNoVerificationApi(phone) {
  const url = `${BASE_URL}phone_no_verification`;
  let formData = new FormData();
  formData.append("phone_no", phone)
  formData.append("phone_no", phone)
  const data = await fetch(url, {
    method: "POST",
    body: formData,
  }).catch((err) => {
    log("Api error: ", err);
  });
  const payload = data && data.json();
  return payload;
}

export async function sendOtpTwillio(phone) {
  const url = `${BASE_URL}send_otp_twillio`;
  let formData = new FormData();
  formData.append("phone_no", phone)
  const data = await fetch(url, {
    method: "POST",
    body: formData,
  }).catch((err) => {
    log("Api error: ", err);
  });
  const payload = data && data.json();
  return payload;
}

