import React from 'react'
import TermAndConitions from '../TermsAndConditions/TermAndConitions';

const TermandConds = () => {
    return (
        <>
          <TermAndConitions />  
        </>
    )
}

export default TermandConds
