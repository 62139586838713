import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import AutoComplete from './AutoComplete';
import { bookServiceApi } from '../../services/apicall'
import './form.css';
import { useHistory, Link } from "react-router-dom";

const FormComponent = ({  customer }) => {

    const [address, setaddress] = useState('');
    const [loggedIn, setLoggedIn] = useState(true);

    const defaultvalues = {
        name: null,
        phone: null,
        service: null,
        city: null,
    }
    const [services, setServices] = useState([]);
    const [State, SetState] = useState(defaultvalues)
   
console.log(State,'state')
    useEffect(() => {
        getServices();
        console.log({customer})
    }, [])


    useEffect(() => {

    if (customer!=null) {
        setLoggedIn(false)
    }
    else{    setLoggedIn(true)
    }
    
        // const loggedInUser = localStorage.getItem("token");
        // if (loggedInUser) {
        //   const foundUser = JSON.parse(loggedInUser);
        //   setCustomer(foundUser);
        // }
      }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        SetState({
            ...State,
            [name]: value,
        })
    }

    const getServices = () => {
        axios.get('https://maahirpro.com/stagging/index.php/api/get_category_list')
            .then(res => {
                setServices(res.data.category)
            })
            .catch(err => console.log(err))
    }

    const sendToAdmin = async () => {
        const token = localStorage.getItem("token")
        // const user = localStorage.getItem("user")

        let formData = new FormData();

        formData.append("customer_name", State.name);
        formData.append("category_id", State.service);
        formData.append("address",address);
        formData.append("token", token)
        formData.append("maahir_id", 0)
        const makeBookServiceRequest = await bookServiceApi(formData)
        if((State.name&&State.service&&token)!==null){       
             alert("We have received your request. We will get back to you shortly.");
    }
    else{
        alert("Something is missing. Fill out all the required fields.")
    }
    }
    let history = useHistory();

    return (
        <>
            <Container >
                <Form id="form-styling" data-aos="fade-right">
                    <Form.Label className='mb-4' style={{ color: 'white', fontSize: '1.5em', display: 'flex', justifyContent: 'center' }}>
                        Book Your Service Now
                    </Form.Label>
                    <Col className="d-flex justify-content-center">
                        <hr style={{ height: "5px", color: '#f76300', marginTop: '-15px', width: '70px' }}></hr>
                    </Col>
                    <Row>
                        <Col xs={12} md={12} className="mb-2">
                            <Form.Control name="name" onChange={handleInputChange} placeholder="Enter Your Name" disabled={loggedIn}/>
                        </Col>
                        {/* <Col xs={12} md={12} className="mb-2">
                            <Form.Control name="phone" onChange={handleInputChange} placeholder="0307 XXXXXXX" />
                        </Col> */}
                        <Col xs={12} md={12} className="mb-2">
                            <Form.Control onChange={handleInputChange} name="service" as="select" disabled={loggedIn}>...
                                <option>Select Service</option>
                                {
                                    services.map(service => {
                                        return (
                                            <option value={service.id}>{service.title}</option>
                                        )
                                    })
                                }
                            </Form.Control>
                        </Col>
                        <Col xs={12} md={12} className="mb-2">
                            <Form.Control onChange={handleInputChange} name="city" as="select" disabled={loggedIn}>...
                                <option value='Islamabad'>Islamabad</option>
                                <option value='Rawalpindi'>Rawalpindi</option>
                            </Form.Control>
                        </Col>
                        <Col xs={12} md={12} className="mb-2">
                            <AutoComplete
                       
                                address={address}
setaddress={setaddress}
                            />
                        </Col>
                        <div class="d-flex justify-content-center" style={{marginTop:'4%'}}>                            <Button 
                            style={{width:'80%', alignSelf:'center'}}
                            // disabled={customer ? "" : "true"}
                             id='booknow' onClick={()=> {customer? sendToAdmin() :   history.push('/customerlogin')}}>{customer? 'Book Now' :'Sign In & Book Now'}</Button></div>

                        
                    </Row>
                </Form>
            </Container>
        </>
    )
}

export default FormComponent;
