import React from 'react';
import Footer from '../../Footer/Footer';
import './FAQs.css';
import HeaderHeading from '../../Header/HeaderHeading';
import { Col, Container, Row ,Image } from 'react-bootstrap';


const FAQs = () => {
  return(
    <>
        <HeaderHeading heading="FAQs" />

        <Container fluid className="about-section">
        <Container>
            <Row>
                    {/* <Col lg={12} className='d-flex justify-content-center '>
                        <h1 style={{fontSize:'40px' , lineHeight: '39px' , fontWeight:'700' }}>Welcome To Maahir</h1>
                    </Col>
                     <Col lg={12} className='d-flex justify-content-center'>
                        <hr style={{width:'100px' , color:'orange' , height:'4px'}}></hr>
                    </Col>
              <Col md={6} className="ms-auto ">
                <p id="aboutPara"  data-aos="fade-right">Maahir is Pakistan's first top service provider that provides around 60+ services from the marketplace and handyman to freelance services. MAAHIR is a way forward to connect local communities with the best technical manpower and that just a few taps away. Maahir’s will always be there for you in the event of a problem. We take care of consumers’ all needs under one platform. We charge no commission for any job performed via Maahir app.
             
                We bring the best user experience with modern and enhanced features. We make sure to provide constant support to all the users by staying in touch and asking for feedback.

                </p>
              </Col> */}
              

              {/* <Col md={6} className="img-container " >
                  <Row className="img-section">
                      <Col lg={6} >
                        <Image src={plumb} alt="Plumber pic" height="200px" data-aos="fade-up"/>
                      </Col>

                      <Col lg={6} >
                        <Image src={plumb}  alt="Plumber pic" height="200px" data-aos="fade-up"/>
                      </Col>

                  </Row>      
              </Col> */}

            <div className="lower-para">
              <div className="row">
                  <div className="col-md-12">
                      <p  data-aos="fade-right" id='aboutPara'>
                     
                      {/* <h3 style={{fontSize:'40px' , lineHeight: '39px' , fontWeight:'700' ,}}>Why Maahir?</h3><br/> */}

<strong>What Is Maahir?</strong><br/>
MAAHIR is a dream, designed to empower Pakistan's working class. It empowers every skilled individual from marketplace to handyman to freelancers to run their virtual businesses using the MAAHIR platform. And to connect local communities with the best skilled and technical manpower.<br/><br/>
<strong>How Do I Register?</strong><br/>
You can Register yourself as a customer through the Web page or using our mobile app (iOS or Andriod). From 2 options choose the Customer option and fill in the basic personal information and then click 'Sign Up'.<br/><br/>
<strong>How can I Hire a Maahir?</strong><br/>
Customers can easily hire a Maahir through the Maahir app which is very simple, just choose a service you require and the app will connect to you to those skilled Maahir who are near to you according to your location.<br/><br/>
<strong>What Should I Do When Maahirs Is Not Responding To My, Message Or Call?</strong><br/>
You can approach us via our helpline or can add a complaint in the app and choose other Maahirs. 
<br/><strong>Whatsapp:</strong> +92 320 0451901<br/>
<strong>Email:</strong>	info@maahirpro.com<br/>
<strong>Landline:</strong>	051-2305301
<br/><br/>
<strong>In Which Cities Do I Book Maahir Services?</strong><br/>
Currently, we are providing our services to Islamabad and Rawalpindi but we are expanding to more cities in Pakistan. 



                      </p>
                  </div>
              </div>
          </div>
      </Row>
      </Container>
      </Container>
   <Footer />

    </>
  );
}

export default FAQs;