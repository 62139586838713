import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SubCat from '../Cards/SubCat';
import AboutUs from '../AboutUs/AboutUs';


const Home = (props) => {

  return (
    <>
      <Header customer={props?.customer} />
      <SubCat />
      <AboutUs />
      <Footer />
    </>
  );
}

export default Home;