import React, { useEffect } from 'react';
import Footer from '../Footer/Footer';
import './PrivacyPolicy.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import tl from '../../assets/tl.png';
import tld from '../../assets/tld.png';

const PrivacyPolicy = () => {


    /* This state is basically initilizing the animation on scroll */

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, []);


 
    return (
        <>
            <div className="container-fluid g-0" style={{backgroundColor:"#fff7e0"}} >
              
                       
                            <ol data-aos="fade-left" className="lsnone">
                            <h3 style={{paddingTop:17}}>Privacy Policy</h3><br/>
                            <li>Last updated: November 30, 2021
    
    </li><br/>
    <li>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</li>
                                <li>The prices charged by Maahirs are based on market prices. We have no input in the
    prices charged for jobs.</li><br/>
    
    <li>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.</li><br/>
    {/* //Cookies */}
    <h1>Interpretation and Definitions</h1>
    <h3>Interpretation</h3>
    <li>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.


    
    </li><br/>
    <h3>Definitions</h3>
    <li>For the purposes of this Privacy Policy:
    
    </li><br/>

    <li>- <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.    </li>
    <li>- <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
    <li>- <strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named Maahir </li>
    <li>- <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to GS Technologies Pvt Ltd, E-11/2 Iteefaq Heights Ground Floor Office#3.</li>
    <li>- <strong>Country</strong> means a unique account created for You to access our Service or parts of our Service. </li>
    <li>- <strong>Device</strong>  refers to: Pakistan </li>
    <li>- <strong>Personal Data</strong>   means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
    <li>- <strong>Service</strong> refers to the Application or the Website or both. </li>
    <li>- <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. </li>
    <li>- <strong>Usage Data</strong>  refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit). </li>
    <li>- <strong>Website</strong>  refers to Maahir, accessible from https://www.maahirpro.com/ </li>
    <li>- <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>

<h1>Collecting and Using Your Personal Data</h1>
<h2>Types of Data Collected</h2>
<h3>Personal Data</h3>
    <li>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</li><br/>
    <li>- Email address</li>
    <li>- First name and last name</li>
    <li>- Phone number</li>
    <li>- Address, State, Province, ZIP/Postal code, City</li>
    <li>- Usage Data</li>
    <h3>Usage Data</h3>
    <li>Usage Data is collected automatically when using the Service.</li><br/>

    <li>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</li><br/>
  
    <li>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</li><br/>
    <li>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</li><br/>
    <h3>Information Collected while Using the Application</h3>
    <li>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.

</li><br/>
  
  
    <li>You can enable or disable access to this information at any time, through Your Device settings.

</li><br/>

<li>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:

</li><br/>

<li>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.

</li><br/>
<li>You can enable or disable access to this information at any time, through Your Device settings.

</li><br/>



<h3>Tracking Technologies and Cookies</h3>
<li>- Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
</li><br/>
<li>- Flash Cookies. Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling, or deleting local shared objects?" available at https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</li>
<li>-Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
<li>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: Use of Cookies by Free Privacy Policy.</li><br/>
<li>We use both Session and Persistent Cookies </li><br/>

<h3>Use of Your Personal Data</h3>
<li></li><br/>
<h3>Retention of Your Personal Data</h3>
<li>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
<br/><br/>
The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</li><br/>
<h3>Transfer of Your Personal Data</h3>

<li>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
<br/><br/>
Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
<br/><br/>
The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a count</li><br/>

<h1>Disclosure of Your Personal Data</h1>
<h3>Business Transactions
</h3>
<li>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</li><br/>
<h3>Law enforcement</h3>
<li>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</li><br/>
<h3>Other legal requirements</h3>
<li>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:

Comply with a legal obligation
Protect and defend the rights or property of the Company
Prevent or investigate possible wrongdoing in connection with the Service
Protect the personal safety of Users of the Service or the public
Protect against legal liability</li><br/>



  
    <h3>Security of Your Personal Data
</h3>
    <li>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
</li>
    <h3>Children's Privacy

</h3>
    <li>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
    <br/><br/>
If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.

</li>

<h3>Links to Other Websites


</h3>
    <li>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
    <br/><br/>
We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.


</li>

    
   
    <h3>Changes to this Privacy Policy
</h3><br/>
<li>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
<br/><br/>
We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
<br/><br/>
You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page</li>    <br/>
    

<h3>Contact Us
</h3><br/>
<li>If you have any questions about this Privacy Policy, You can contact us:
<br/><br/>
By email: info@maahirpro.com
<br/><br/>
By visiting this page on our website: www.maahirpro.com
<br/><br/>
By phone number: 051-2305301</li>    <br/>
    
                            </ol>
           
            </div>
    
    
            {/* <div className=" rightside " style={{ backgroundColor: "#fff7df", paddingTop: "7em" }}>
                <img src={tld} className="plumber" alt="Pic of worker" />
            </div> */}
    
            <Footer />
        </>
    )
}

export default PrivacyPolicy;
