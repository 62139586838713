import React from 'react';
import Footer from '../../Footer/Footer';
import './AboutUs.css';
import HeaderHeading from '../../Header/HeaderHeading';
import plumb from '../../../assets/plumb.png';
// import graphics from '../../../assets/graphics9.png';
import { Col, Container, Row ,Image } from 'react-bootstrap';


const AboutUs = () => {
  return(
    <>
        <HeaderHeading heading="About Us" />

        <Container fluid className="about-section">
        <Container>
            <Row>
                    <Col lg={12} className='d-flex justify-content-center '>
                        <h1 style={{fontSize:'40px' , lineHeight: '39px' , fontWeight:'700' ,}}>Welcome To Maahir</h1>
                    </Col>
                     <Col lg={12} className='d-flex justify-content-center'>
                        <hr style={{width:'100px' , color:'orange' , height:'4px'}}></hr>
                    </Col>
              <Col md={6} className="ms-auto ">
                <p id="aboutPara"  data-aos="fade-right">Maahir is Pakistan's first top service provider that provides around 60+ services from the marketplace and handyman to freelance services. MAAHIR is a way forward to connect local communities with the best technical manpower and that just a few taps away. Maahir’s will always be there for you in the event of a problem. We take care of consumers’ all needs under one platform. We charge no commission for any job performed via Maahir app.
             
                We bring the best user experience with modern and enhanced features. We make sure to provide constant support to all the users by staying in touch and asking for feedback.

                </p>
              </Col>
              

              <Col md={6} className="img-container " >
                  <Row className="img-section">
                      <Col lg={6} >
                        <Image src={plumb} alt="Plumber pic" height="200px" data-aos="fade-up"/>
                      </Col>

                      <Col lg={6} >
                        <Image src={plumb}  alt="Plumber pic" height="200px" data-aos="fade-up"/>
                      </Col>

                  </Row>      
              </Col>

            <div className="lower-para">
              <div className="row">
                  <div className="col-md-12">
                      <p  data-aos="fade-right" id='aboutPara'>
                     
                      <h3 style={{fontSize:'40px' , lineHeight: '39px' , fontWeight:'700' ,}}>Why Maahir?</h3><br/>
We bring the best user experience with modern and enhanced features. We make sure to provide constant support to all the users by staying in touch and asking for feedback.
The following are the most important features of our success:
<br/><br/>
<strong>Commission or charges</strong><br/>
On jobs Maahir app will not take any commissions, and also the rate or pricing will be fixed between you and the Maahir. When Maahir comes to check your problem, you will not be charged even a single dime. However, Maahir deserves to get visit charges payment.
<br/><br/>
<strong>Security and Quality</strong><br/>
We take security and quality of our customers very seriously, all of our Maahirs are CNIC verified. And for our freelancer services the qualification and experience of Maahirs are verified. To ensure your safety, use the map and the location of your phone.
<br/><br/>
<strong>Punctuality</strong><br/>
Maahir Team is concerned about fixing problems quickly. Therefore, anytime you want assistance, there is always a Maahir accessible. Because the Maahir you discover are dependent on their location. You will always be able to find someone who is closer to you.
<br/><br/>
<strong>No string attached</strong><br/>
No annual charges, no subscription charges. Simply pay the Maahir the agreed amount. and you're done. You get exactly what you paid for with Maahir
.<br/><br/>
<strong>Customer satisfaction guaranteed</strong><br/>
For us your satisfaction and value are all we care. Maahir has a solid Policy on customer satisfaction that includes information on Maahir evaluations and warranties. To assure you get exactly what you paid for or maybe even more.
<br/><br/>
<strong>Highly Trained Maahirs</strong><br/>
Each Maahir of our team undergo extreme training modules, to make sure during our job, Maahirs are highly ethical and follow SOPs.
<br/><br/>
<strong>Constant assistance</strong><br/>
We stay in touch via email, phone, toll free number, and Whatsapp. We make sure to assist all the Maahir family.
                      </p>
                  </div>
              </div>
          </div>
      </Row>
      </Container>
      </Container>
   <Footer />

    </>
  );
}

export default AboutUs;