import React from 'react';
import { Container, Row , Col, Button} from 'react-bootstrap';
import './about.css'
import { useHistory } from "react-router-dom";

const AboutUs = () => {
    let history = useHistory();
    const scrollToTop = () =>{
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
        /* you can also use 'auto' behaviour
            in place of 'smooth' */
        });
    };
    return (
        <>
        <Container fluid style={{  backgroundColor:'#fff7df'}}>
            <Container className='py-5' data-aos="fade-right">
                <Row>
                    <Col lg={12} className='d-flex justify-content-center'>
                        <h1 style={{fontSize:'40px' , lineHeight: '39px' , fontWeight:'700' ,}}>About Maahir</h1>
                    </Col>
                    <Col lg={12} className='d-flex justify-content-center'>
                        <hr style={{width:'100px' , color:'#fbb11d' , height:'4px'}}></hr>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <p style={{fontWeight:'520', lineHeight:'24px', fontFamily: "open sans , sans-serif" ,fontSize:'16px' , textAlign:'center'}}>
                    Maahir is Pakistan's first top service provider that provides around 60+ services from the marketplace and handyman to freelance services. MAAHIR is a way forward to connect local communities with the best technical manpower and that just a few taps away. Maahir’s will always be there for you in the event of a problem. We take care of consumers’ all needs under one platform. We charge no commission for any job performed via Maahir app.                        </p>
                        <Col  className='d-flex justify-content-center py-4'>
                            <Button onClick={()=>   { history.push('/customersignup1')
                        scrollToTop()}} id='customer'>Become A Customer</Button>
                        </Col>
                    </Col>
                </Row>
                
            </Container>
                <Row>
                    <Col className='py-4'>
                        <hr></hr>
                    </Col>
                </Row>
        </Container>    
        </>
    )
}

export default AboutUs
