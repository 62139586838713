import React, { useState, useEffect } from 'react';
import axios from 'axios'
import './Category.css';
import cirlpic from '../../assets/Rect2.png';
import { Container, Row, Image, Col, Button } from 'react-bootstrap';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';




const SubCat = () => {
    const scrollToTop = () =>{
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
        /* you can also use 'auto' behaviour
            in place of 'smooth' */
        });
    };
    const [services, setServices] = useState([]);
    const [loading, setloading] = useState(false);
    const [searchService, setsearchService] = useState();
    const [allData, setallData] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [searchedServices, setsearchedServices] = useState(null);
  
    useEffect(() => {
        getServices();
    }, [])

    const getServices = async () => {
        try {
            const data = await axios.get('https://maahirpro.com/stagging/index.php/api/get_category_list')
                .then(res => {
                    setServices(res.data.category.sort((a, b) => a.title.localeCompare(b.title)));
                    setallData(res.data.category);
                    setsearchedServices(res.data.category.sort((a, b) => a.title.localeCompare(b.title)))

                })
            setloading(true);
        }
        catch (err) { console.log(err) }
    }

    // const onSearchClick = () => {
    //     setServices(searchService === '' ? allData : services.filter(service => searchService === service.title));
    // }
    // const onSearchEmail = (val) => {
    //     // setSearch(val)


    // }
    const onChangeHandler = (searchService) => {
        setsearchService(searchService)

        var displayed = allData.filter((el) => {
            let searchValue = el.title;
            return searchValue.toLowerCase().indexOf(searchService.toLowerCase()) !== -1;
        })
        setsearchedServices(displayed)



        // let matches = [];
        // if (searchService.length > 0) {
        //     matches = services.filter(ser => {
        //         const regex = new RegExp(`${searchService}`, 'gi');
        //         return ser.title.match(regex)
        //     })
        // }
        // console.log(matches, 'match')
        // setSuggestions(matches)

    }


    return (
        <>
            <Container fluid className="whole-div">
                <Container>
                    <Row>
                        <Col sm={12} >
                            <Container>
                                <Row class="height d-flex justify-content-center align-items-center">
                                    <Col>
                                        <div class="search">
                                            <i class="fa fa-search"></i>
                                            <input
                                                type="all"
                                                className="form-control"
                                                placeholder="Search for Servcies"
                                                onChange={event => onChangeHandler(event.target.value)}
                                                value={searchService}
                                            />
                                            {suggestions && suggestions.map((suggestions, i) => {
                                                <div key={i} className='suggest col-md-12 justify-content-md-center'>
                                                    {suggestions?.description}
                                                </div>
                                            })}
                                            {/* <button className="btn btn-primary" onClick={() => onChangeHandler(searchService)}>Search</button> */}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row>

                        {loading ?
                            searchedServices?.map((service, index) =>

                                <Col lg={4} md={6} sm={12} className="py-5 text-center" data-aos="fade-right">
                                    <Image src={cirlpic} className="circle-div" alt="Circle pic" />
                                    {service.icon ? <Image className="services-pics" src={"https://maahirpro.com/stagging/images/categories/" + service.icon} width='70px' height='70px' alt='circle ' /> : null}
                                    <h4>{service.title}</h4>
                                    {/* <p>{service.description}</p> */}
                                    <Button onClick={scrollToTop} id='booknowservice'>Book Now</Button>
                                </Col>
                            )
                            :
                            <Stack className='d-flex justify-content-center' sx={{ color: 'grey.500' }} spacing={2} direction="row">
                                <CircularProgress style={{ color: 'orange' }} />
                            </Stack>
                        }
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default SubCat;